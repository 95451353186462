import React from "react";
import { Link } from "gatsby";

import Slider from "react-slick";
const sliderProps = {
  infinite: true,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

function Brand(props) {
  return (
    <div>
      <div className="heading text-center">
        <h2 className="title">Brands We Work With</h2>
        <p className="title-desc">
          We offer an extensive selection of world-class products from some of
          the most trusted names in flooring.
        </p>
      </div>{" "}
      <Slider className=" bg-white"
        style={{ backgroundColor: "white !important" }}
        {...sliderProps}
        slidesToShow={5}
        slidesToScroll={1}
        arrows={true}
      >
        <Link to="#" className="brand mr-0">
          <img
            width="50"
            height="50"
            loading="lazy"
            src={"/assets/images/brands/1.png"}
            alt={"Brand1"}
          />
        </Link>

        <Link to="#" className="brand mr-0">
          <img
            loading="lazy"
            width="50"
            height="50"
            src={"/assets/images/brands/4.png"}
            alt={"Brand4"}
          />
        </Link>
        <Link to="#" className="brand mr-0">
          <img
            loading="lazy"
            width="50"
            height="50"
            src={"/assets/images/brands/6.png"}
            alt={"Brand6"}
          />
        </Link>

        <Link to="#" className="brand mr-0">
          <img
            loading="lazy"
            width="50"
            height="50"
            src={"/assets/images/brands/7.png"}
            alt={"Brand7"}
          />
        </Link>
        <Link to="#" className="brand mr-0">
          <img
            loading="lazy"
            width="50"
            height="50"
            src={"/assets/images/brands/8.png"}
            alt={"Brand8"}
          />
        </Link>
      </Slider>
    </div>
  );
}

export default Brand;
