import React from "react";
import MainLayout from "../layouts/MainLayout";
import { Link } from "gatsby";
import Banner from "../components/home/banner";
// import BannerSection from "../components/home/banner-section";
import Service from "../components/features/service";
// import Custom Components
import NewsletterModal from "../components/features/modal/newsletter-modal";
import Brand from "../components/features/brand";
import ProductCategoryOne from "../components/features/product-category/product-category-one";

import Slider from "react-slick";

import Products from "../components/home/Products";
import RecentlyViewed from "../components/home/RecentlyViewed";
import SubscriptionForm from "../components/common/SubscriptionForm";
import SEO from "../components/common/SEO";
import { graphql } from "gatsby";
import { safeContent } from "../utils";

const Index = ({ data, location }) => {
  //   if (location.pathname === withPrefix ("/")){
  //     require("../components/home/style.scss")
  // }
  //     else{
  //         console.log("fail")
  //     }
  return (
    <>
      <MainLayout>
        <div className="main home-page">
          <SEO title="Jimenez Store Mr. Discount" />
          {/* Home Banner */}
          <section className="p-0">
            <Slider className="slide-1 home-slider">
              <div>
                <div className="home home1 text-center intro-slide">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        <h3 className={`intro-subtitle text-third`}>
                          No Joke!
                        </h3>
                        <h1
                          className="intro-title"
                          dangerouslySetInnerHTML={safeContent(
                            "The Best  <br/> Bargains in Town. PERIOD"
                          )}
                        ></h1>

                        {/* <div
                          className="intro-price"
                          dangerouslySetInnerHTML={safeContent(
                            '<sup class="intro-old-price">$349,95</sup><span class="text-third">&nbsp;$279<sup>.99</sup></span>'
                          )}
                        ></div> */}

                        <Link
                          to={`/allcollection`}
                          className="btn btn-primary btn-round"
                        >
                          <span>Shop</span>
                          <i
                            aria-label="arrow button"
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="home home2 text-center intro-slide">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        <h3 className={`intro-subtitle text-third`}>
                          Our mission is to save you $$
                        </h3>
                        <h1
                          className="intro-title"
                          dangerouslySetInnerHTML={safeContent(
                            "Everyday is <br/> A Black Friday"
                          )}
                        ></h1>

                        {/* <div
                          className="intro-price"
                          dangerouslySetInnerHTML={safeContent(
                            '<sup class="intro-old-price">$349,95</sup><span class="text-third">&nbsp;$279<sup>.99</sup></span>'
                          )}
                        ></div> */}

                        <Link
                          to={`/allcollection`}
                          className="btn btn-primary btn-round"
                        >
                          <span>Shop</span>
                          <i
                            aria-label="arrow button"
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home home3 text-center intro-slide">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        <h3 className={`intro-subtitle text-third`}>
                          All type of flooring up to 65% OFF
                        </h3>
                        <h1
                          className="intro-title"
                          dangerouslySetInnerHTML={safeContent(
                            "Vinyl, Hardwood   <br/> Carpet, and more"
                          )}
                        ></h1>

                        {/* <div
                          className="intro-price"
                          dangerouslySetInnerHTML={safeContent(
                            '<sup class="intro-old-price">$349,95</sup><span class="text-third">&nbsp;$279<sup>.99</sup></span>'
                          )}
                        ></div> */}

                        <Link
                          to={`/allcollection`}
                          className="btn btn-primary btn-round"
                        >
                          <span>Shop</span>
                          <i
                            aria-label="arrow button"
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="home home4 text-center intro-slide">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        <h3 className={`intro-subtitle text-third`}>
                          Welcome to Jimenez Mr Discount
                        </h3>
                        <h1
                          className="intro-title"
                          dangerouslySetInnerHTML={safeContent(
                            "We sell different categories <br/> of products"
                          )}
                        ></h1>

                        {/* <div
                          className="intro-price"
                          dangerouslySetInnerHTML={safeContent(
                            '<sup class="intro-old-price">$349,95</sup><span class="text-third">&nbsp;$279<sup>.99</sup></span>'
                          )}
                        ></div> */}

                        <Link
                          to={`/allcollection`}
                          className="btn btn-primary btn-round"
                        >
                          <span>Shop</span>
                          <i
                            aria-label="arrow button"
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div> */}
                {/* <div className="home home5 text-center intro-slide">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        <h3 className={`intro-subtitle text-third`}>
                          Welcome to Jimenez Mr Discount
                        </h3>
                        <h1
                          className="intro-title"
                          dangerouslySetInnerHTML={safeContent(
                            "New Flooring <br/> Just Delivered! <br/> Starting from $0.49 sqf"
                          )}
                        ></h1>

                        <div
                          className="intro-price"
                          dangerouslySetInnerHTML={safeContent(
                            '<sup class="intro-old-price">$349,95</sup><span class="text-third">&nbsp;$279<sup>.99</sup></span>'
                          )}
                        ></div>

                        <Link
                          to={`/allcollection`}
                          className="btn btn-primary btn-round"
                        >
                          <span>Shop</span>
                          <i
                            aria-label="arrow button"
                            className="icon-long-arrow-right"
                          ></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
            */}
              {/* </div> */}
            </Slider>
          </section>

          {/* <div className="mb-5">
            <Slider className="slide-1 home-slider" {...thumbnailsSliderProps}>
              {data.allSliderImages.edges.map(({ node }, key) => (
                <IntroSlide
                  data={data.allMockDataJson.nodes[0].introBanners}
                  images={node}
                  key={key}
                  index={key}
                />
              ))}
            </Slider>
          </div>
        */}
          <div className="container">
            <h2 className="title text-center mt-6 mb-4">
              Explore Popular Categories
            </h2>

            <div className="cat-blocks-container">
              <div className="row">
                {data.allFeatCategories.nodes.map((item, index) => (
                  <Banner data={item} key={index} />
                ))}
              </div>
            </div>
          </div>
          <div className="mb-1"></div>
          <div className="container-fluid">
            <div className="intro-section pt-5 pb-4">
              <div className="col-lg-12">
                <div className=" slider-container-ratio  mb-2 mb-lg-0">
                  <div className="container-fluid">
                    <h2 className="title text-center pt-3 mb-4">
                      Browse Our Premium Flooring Options
                    </h2>
                    <div className="row justify-content-center">
                      <div className=" col-sm-2 col-md-2 col-lg-2">
                        <ProductCategoryOne
                          image={`assets/images/category/hardwood-flooring.png`}
                          category="Hardwood Flooring"
                          // count="18"
                        />
                      </div>

                      <div className="col-sm-2 col-md-2 col-lg-2">
                        <ProductCategoryOne
                          image={`assets/images/category/laminate-wood.webp`}
                          category="Laminated Wood"
                          count="12"
                        />
                      </div>

                      <div className="col-sm-2 col-md-2 col-lg-2">
                        <ProductCategoryOne
                          image={`assets/images/category/Pre-vinyl.png`}
                          category="Vinyl Plank Flooring"
                          count="12"
                        />
                      </div>
                      <div className="col-sm-2 col-md-2 col-lg-2">
                        <ProductCategoryOne
                          image={`assets/images/category/rugs.jpg`}
                          category="Carpet"
                          count="12"
                        />
                      </div>
                    </div>
                  </div>
                  <Brand />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6"></div>

          <div className="container">
            <div
              className="cta cta-border mb-5"
              style={{ backgroundImage: `url(/assets/images/home/bg-1.jpg)` }}
            >
              <img
                src={`/assets/images/home/banners/jiminez-strong.png`}
                style={{ marginTop: "-3rem", marginLeft: "2rem" }}
                alt="camera"
                className="cta-img"
              />
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="cta-content" style={{ display: "flex" }}>
                    <div className="cta-text text-right text-white">
                      <p>
                        Same Brands , but for less $$ <br />
                        <strong>UP TO 65% OFF</strong>
                      </p>
                    </div>
                    <Link
                      to={`/allcollection`}
                      className="btn btn-outline-dark2 text-white btn-round "
                      style={{backgroundColor:'black'}}
                    >
                      <span>Shop Now</span>
                      <i
                        aria-label="arrow button"
                        className="icon-long-arrow-right"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <BannerSection data={data.allMockDataJson.nodes[0].bannerContents} /> */}

          {/* <div className="mb-3"></div> */}

          {/* <NewCollection /> */}

          <div className="mb-6"></div>
          {/* <div className="container">
            <div className="heading text-center mb-3">
              <h2 className="title">Deals & Outlet</h2>
              <p className="title-desc">Today’s deal and more</p>
            </div>

            <div className="row">
              {data.allMockDataJson.nodes[0].dealContents.map((item, index) => (
                <div className="col-lg-6 deal-col" key={index}>
                  <DealProduct data={item} />
                </div>
              ))}
            </div>
          </div> */}

          <Products />

          <Service />
          <RecentlyViewed />
        </div>
        <SubscriptionForm />
        <NewsletterModal demo="4" />
      </MainLayout>
    </>
  );
};

export default Index;
export const query = graphql`query MyQuery {
  allSliderImages {
    edges {
      node {
        url {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  allFeatCategories {
    nodes {
      name
      itemCount
      seoMetaData {
        slug
      }
      thumbnail {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 300
            quality: 100
            
          )
        }
      }
    }
  }
  allMockDataJson {
    nodes {
      bannerCategories {
        image
        name
      }
      bannerContents {
        sub_title
        title
        title_str
        title_br
        img
      }
      dealContents {
        headerTitle
        headerSubTitle
        productTitle
        newPrice
        oldPrice
        dataUntil
        bigImage
        linkTitle
        link
      }
      introBanners {
        image
        subtitle
        subtitleAdClass
        title
        price
        btnText
      }
    }
  }
}
`;
