import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";

import ProductNine from "../features/product/product-sm";

import {
  addToCart,
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
} from "../../actions";

const sliderProps = {
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = { products: [] };
  }

  async componentDidMount() {
    const { products } = this.props;

    if (products.length > 0) this.setState({ products });

    const result = JSON.parse(localStorage.getItem("recentItems")) || [];
  
    if (result) {
      const newProducts = products.filter((product) => {
        return result.includes(product._id);
      });

      this.setState({ products: newProducts });
    }
  }

  render() {
    const count = this.state.products.length;
    return (
      <div className="bg-light pt-5 pb-6">
        <div className="container trendy-collection">
          <div className="heading heading-center mb-3">
            {/* <h2 className="title-lg">Trendy Products</h2> */}
          </div>
        </div>
        <div className="row text-center" style={{ textAlign: "center" }}>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div className="tab-content  just-action-icons-sm center-block">
              <div className="container">
                <h2 className="title text-center mb-3">
                  Recently Viewed Products
                </h2>

                <div className="row">
                  <div
                    className={
                      count >= 4 ? "col" : count >= 3 ? "col-7" :count>=2? "col-4" :"col-2"
                    }
                  >
                    <Slider
                      {...sliderProps}
                      slidesToShow={count > 6 ? 6 : count}
                      className=""
                    >
                      {this.state.products.map((item, index) => (
                        <ProductNine
                          product={item}
                          key={index}
                          onAddToCart={addToCart}
                          onToggleWishlist={toggleWishlist}
                          onAddToCompare={addToCompare}
                          showQuickView={showQuickViewModal}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Index = () => (
  <StaticQuery
    query={graphql`{
  products: allProducts(filter: {title: {ne: null}}) {
    nodes {
      _id
      title
      description
      quantity
      price
      brand
      oldPrice
      rating
      isDefaultShippingEnabled
      isDeliverable
      isPickup
      isShippable
      seoMetaData {
        slug
      }
      image: thumbnail {
          childImageSharp {
            gatsbyImageData( quality: 100, layout: CONSTRAINED)
          }
      }
      thumbnail {
          childImageSharp {
            gatsbyImageData( quality: 100, layout: CONSTRAINED)
          }
      }
      condition
    }
  }
}
`}
    render={({ products }) => <Products products={products.nodes} />}
  />
);

export default Index;
