import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { encode } from "../../../utils";

// import { isIEBrowser } from '../../../utils';
import { removeNewsletterMdoal } from "../../../actions";

const customStyles = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
  },
  overlay: {
    backgroundColor: "rgba(51,51,51,0.6)",
    zIndex: "10000",
  },
};

function NewsletterModal(props) {
  const { newsletter } = props;
  const [open, setOpen] = useState(false);
  const [status, setstatus] = useState("init");
  const [email, setEmail] = useState("");
  const [name, setname] = useState("");
  const [closeType, setCloseType] = useState(null);

  const timer = useRef(null);
  useEffect(() => {
    Modal.setAppElement("#___gatsby");

    timer.current = setTimeout(() => {
      if (
        newsletter &&
        window.localStorage.getItem("closeType") !== "forever"
      ) {
        setOpen(true);
      }
    }, 10000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [newsletter, closeType]);

  function changeCloseType(e) {
    if (document.querySelector("input[type='checkbox']").checked === true) {
      if (typeof window !== "undefined")
        window.localStorage.setItem("closeType", "forever");
      setCloseType("forever");
    } else {
      if (typeof window !== "undefined")
        window.localStorage.setItem("closeType", "once");
      setCloseType("once");
    }
  }

  function closeModal(e) {
    // if (closeType === "forever") {
    //   removeNewsletterMdoal();
    // }

    setOpen(false);

    document
      .getElementById("newsletter-popup-form")
      .classList.remove("ReactModal__Content--after-open");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        firstName:name,
        email: email,
        newsLetterRequest: {
          isNewsLetter: true
        },
      }),
    })
      .then((res) => {
        // this.setState({ status: "success", email: "", name: "" });
        setstatus("success");
        setEmail("");
        setname("");
        window.localStorage.setItem("closeType", "forever");
      })
      .catch((err) => {
        setstatus("failed");
        console.log(err);
      });

    e.preventDefault();
  };


  return (
    <Modal
      isOpen={open}
      // appElement={document.getElementById('body')}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      shouldFocusAfterRender={false}
      contentLabel="Newsletter Modal"
      className="container newsletter-popup-container"
      id="newsletter-popup-form"
    >
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row no-gutters bg-white newsletter-popup-content">
            <div className="col-xl-3-5col col-lg-7 banner-content-wrap">
              <div className="banner-content text-center">
                {/* <img
                  src={`/assets/images/popup/newsletter/logo.png`}
                  alt="logo"
                  className="logo"
                  width="60"
                  height="15"
                /> */}
                <h2 className="banner-title">
                  {/* get{" "} */}
                  <span>
                    Want 10<span style={{ fontWeight: "400" }}>%</span>
                  </span>{" "}
                  off?
                </h2>
                <p>
                  Sign up to recieve updates on new products announcements,
                  special promotions, sales and more.
                </p>

                <form
                  className="subscribe-form"
                  name="subscribe"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                 

                  <div className="input-group ">
                    {/* style={ isIEBrowser() ? { border: 'none' } : {} } */}
                    <input
                      type="email"
                      className="form-control form-control-white"
                      placeholder="Enter your Email Address"
                      aria-label="Email Adress"
                      required
                      name="email"
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </div>

                  <div className="input-group ">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter your name"
                      onChange={(e)=>setname(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        disabled={
                          status === "submitting" || status === "success"
                        }
                        type="submit"
                      >
                        <span>Subscribe</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
                {status === "success" && (
                  <div className="text-success text-center">
                    <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
                    You've successfully subscribed our Newsletter.
                  </div>
                )}
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="register-policy-2"
                    defaultChecked={closeType === "forever"}
                    onChange={changeCloseType}
                  />
                  {/* style={ isIEBrowser() ? { padding: '2' } : {} } */}
                  <label
                    className="custom-control-label"
                    htmlFor="register-policy-2"
                  >
                    Do not show this popup again
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{ backgroundColor: "rgb(23, 37, 74)" }}
              className="col-xl-2-5col col-lg-5 "
            >
              <img
                src={`/assets/images/newsletter/logo-260px.png`}
                alt="newsletter"
                className="newsletter-img"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={closeModal}
      >
        <span>×</span>
      </button>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    newsletter: state.modal.newsletterModal,
  };
}

export default connect(mapStateToProps, { removeNewsletterMdoal })(
  NewsletterModal
);
