import React from "react";
import { Link } from "gatsby";
import slugify from "slugify";

function ProductCategoryOne(props) {
  const { image, category } = props;

  return (
    <div className="banner banner-cat">
      <Link to={`/collections/${slugify(category.toLowerCase())}`}>
        <img loading="lazy" width="150" height="150" src={image} alt={category} />
      </Link>

      <div
        className={`banner-content  banner-content banner-content-overlay text-center`}
      >
        {/* <h3 className="banner-title">{ category }</h3>
                <h4 className="banner-subtitle">{ count } Products</h4> */}
        <Link
          to={`/collections/flooring/${slugify(category.toLowerCase())}`}
          style={{ width: "100%" }}
          className="banner-subtitle"
        >
          {category}
        </Link>
      </div>
    </div>
  );
}

export default React.memo(ProductCategoryOne);
