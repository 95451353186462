import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import slugify from "slugify";

function banner(props) {
  const { thumbnail, name,seoMetaData } = props.data;
  return (
    <div className=" col-sm-12 col-lg-3 .offset-lg-1 ">
      <Link
        to={`/collections/${slugify(
          seoMetaData?.slug || name.toLowerCase()
        )}/`}
        className="cat-block"
        style={{maxWidth:"200px"}}
      >
            <GatsbyImage
              image={thumbnail.childImageSharp.gatsbyImageData}
              // className="img-fluid bg-img"
              alt=""
            />
        

        <h3 className="cat-block-title">{name}</h3>
      </Link>
    </div>
  );
}

export default React.memo(banner);
