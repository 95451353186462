import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import slugify from "slugify";

import { GatsbyImage } from "gatsby-plugin-image";

import { addToCart } from "../../../reducers/cart";
import { showQuickViewModal } from "../../../actions";

function ProductNine(props) {
  const { product, addToCart, showQuickView, type = 1 } = props;

  const [stock] = useState(product.quantity);
  const [quantity] = useState(1);
  const addToCartHandler = () => {
    if (0 !== stock) addToCart({ ...product, quantity, stock });
  };

  const quickViewHandler = () => {
    // console.log("quickview");
    showQuickView(product);
  };

  return product ? (
    <div className="product product-2 mr-2">
      <figure className="product-media">
        {product.discount ? (
          <span className="product-label label-circle label-sale">Sale</span>
        ) : (
          ""
        )}
        {0 === product.stock ? (
          <span className="product-label label-circle label-out">Out</span>
        ) : (
          ""
        )}

        <Link
          to={`/products/${
            product.seoMetaData?.slug || slugify(product.title.toLowerCase())
          }/`}
        >
          {" "}
          {/* <div style={{display:'flex',width:'100%'}}> */}
          <GatsbyImage
            image={product.image.childImageSharp.gatsbyImageData}
            alt="product"
            // style={{ display: "flex", maxWidth: "100%" }}
            className="img-fluid"
          />
          {/* </div> */}
        </Link>

        <div className="product-action product-action-dark">
          <button className="btn-product btn-cart" onClick={addToCartHandler}>
            {type === 1 ? <span>add to cart</span> : ""}
          </button>

          <button
            className="btn-product btn-quickview"
            title="Quick view"
            onClick={quickViewHandler}
          >
            {type === 1 ? <span>quick view</span> : ""}
          </button>
        </div>
      </figure>
    </div>
  ) : (
    ""
  );
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    showQuickView: (item) => dispatch(showQuickViewModal(item)),
  };
}

export default connect(null, mapDispatchToProps)(ProductNine);
